<template>
  <s-button
    variant="primary"
    size="md"
    class="!min-w-[11.6rem] absolute right-0 top-[-85px]"
    @click="onInputProductName"
  >
    {{ $t('studio.prj_prod.this_prod.landing_create_btn') }}
  </s-button>
  <st-box>
    <div
      v-if="productPageList.length === 0"
      class="mx-auto flex w-[37rem] flex-col items-center gap-20 py-20"
    >
      <img
        src="@/assets/images/product/product-page-image.svg"
        alt="product-page-img"
        class="block h-188 w-360 object-cover"
      />
      <h3 class="break-keep text-center text-3xl font-bold leading-2xl text-on-surface-elevation-1">
        <safe-html tag="span" :html="$t('studio.prj_prod.this_prod.landing_msg2')" />
      </h3>
      <safe-html
        tag="p"
        class="break-keep text-center text-md leading-lg text-on-surface-elevation-3"
        :html="$t('studio.prj_prod.this_prod.landing_msg3')"
      />
    </div>

    <st-table
      v-else
      :columns="columns"
      :data="productPageList"
      tableClass="[&>tbody>tr:hover>td:nth-child(3)>span]:underline"
      :showHoverEffect="true"
      :paging="{ total, pageSize, show: true }"
      @pageChange="onPageChange"
    >
      <template #col-0="{ row: page }">
        <st-td aLeft @click="handleGoToStore(page)">
          <s-tooltip
            v-if="page.progressStatus === PROGRESS_STATUS.LIVE"
            arrow
            :content="`<p class='text-center'>${$t(
              'studio.prod_page.tooltip_go_to_store_page'
            )}</p>`"
            :duration="0"
            :distance="4"
            useFlip
            flipOnUpdate
            placement="top"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="2501"
            :allowHTML="true"
            class="flex items-center gap-2"
          >
            <template #target>
              <span class="font-medium text-brand-primary">{{ $t(page.statusLabel) }}</span>
              <s-icon
                size="xl"
                :icon="'ic-v2-control-arrow-right-line'"
                class="shrink-0 text-brand-primary"
              />
            </template>
          </s-tooltip>

          <span
            v-else
            :class="{
              'text-system-red':
                page.progressStatus === PROGRESS_STATUS.REJECT ||
                page.progressStatus === PROGRESS_STATUS.SUSPEND
            }"
          >
            {{ $t(page.statusLabel) }}
          </span>
        </st-td>
      </template>
      <template #col-1="{ row: page }">
        <st-td aLeft>
          <s-text
            v-if="page.progressStatus === PROGRESS_STATUS.LIVE"
            as="span"
            role="text3"
            class="font-medium"
          >
            {{ page.pageDisplayYn === Confirmation.YES ? DISPLAY_STATUS.ON : DISPLAY_STATUS.OFF }}
          </s-text>
          <span v-else class="font-medium">-</span>
        </st-td>
      </template>
      <template #col-3="{ row: page }">
        <st-td aLeft>
          <span class="font-medium">{{ getDateTimeByLocale(page.createdAt) }}</span><br />
          <span class="font-medium">({{ getDateTimeByLocale(page.pageUpdatedAt || 0) }})</span>
        </st-td>
      </template>
      <template #col-4="{ row: page }">
        <st-td aLeft>
          <p class="leading-sm">
            {{ page.period }}
          </p>
        </st-td>
      </template>
      <template #col-5="{ row: page }">
        <st-td onlyButton>
          <s-menu-popup
            :distance="0"
            :offset="[-8, 14]"
            placement="left-start"
            trigger="click"
            class="inline-block"
          >
            <template #target>
              <s-icon
                size="3xl"
                icon="ic-v2-content-option-vertical-fill"
                class="text-on-surface-elevation-2"
              />
            </template>
            <template #menuItems>
              <s-menu-popup-item
                v-for="opt in page.options"
                :key="opt.value"
                class="min-w-[13.8rem]"
                :value="opt.value"
                :class="{ 'text-tint-red-a400': opt.value === 'delete' }"
                @click="handleMoreOptionClick(page, opt.value)"
              >
                {{ $t(opt.label) }}
              </s-menu-popup-item>
            </template>
          </s-menu-popup>
        </st-td>
      </template>
    </st-table>
  </st-box>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { deleteProductPageApi, fetchProductPageInfoApi } from '@/apis/product-page.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import DuplicateProductDialog from '@/components/product-page/duplicate-product-dialog.vue';
import InputProductNameDialog from '@/components/product-page/input-product-name-dialog.vue';
import ModifyDisplayDialog from '@/components/product-page/modify-display-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MODE, SORT_DIRECTION } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { DISPLAY_STATUS } from '@/constants/product-page.const';
import { Confirmation } from '@/enums/common.enum';
import { PROGRESS_STATUS } from '@/enums/product-page.enum';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { ProductPage } from '@/types/product-page.type';
import type {
  ProductPageActionType,
  ProductPageModel
} from '@/types/product-page/product-page-model.type';
import type { ProductPageResponse } from '@/types/product-page/product-page-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { getConfigs, redirectTo, sortValueToDirection } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getStatusLabel } from '@/utils/product-page.util';

const { checkProductPermission } = useApp();

const PRODUCT_PAGE_ACTION = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
  RESUME: 'resume',
  SUSPEND: 'suspend',
  DELETE: 'delete'
} as const;

const handleOnRowClick = (item: ProductPage) => {
  redirectTo(`${path.value}/${MODE.EDIT}?pageId=${item.id}`);
};

const columns = ref<Column[]>([
  {
    title: 'studio.prj_prod.this_proj.this_prod.status_header1',
    dataIndex: 'status',
    width: '11.2rem',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.prj_prod.this_proj.this_prod.display_header2',
    width: '9.6rem',
    dataIndex: 'pageDisplayYn',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.prj_prod.this_proj.this_prod.prod_page_name_header3',
    width: 'auto',
    sortKey: 'PAGE_NAME',
    dataIndex: 'pageName',
    cellClass: 'px-12 py-16',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.prj_prod.this_proj.this_prod.registered_edited_date_header4',
    width: '16rem',
    sortKey: 'REGISTRATION_DATE',
    dataIndex: 'pageUpdatedAt',
    cellClick: handleOnRowClick,
    titleTooltip: 'studio.prj_prod.this_proj.this_prod.registered_edited_date_tooltip',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  {
    title: 'studio.prj_prod.this_proj.this_prod.release_period_header5',
    width: '16rem',
    sortKey: 'DURATION',
    dataIndex: 'period',
    cellClick: handleOnRowClick,
    onSort: ({ sortValue, sortKey }: ColumnSorting) => handleSort(sortValue, sortKey)
  },
  { title: '', width: '4rem' }
]);

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const { t, locale } = useI18n();
const route = useRoute();

const productNo = route.params.productId as string;

const productPageList = ref<ProductPageModel[]>([]);

const moreOptions: FormOption<ProductPageActionType>[] = [
  {
    label: 'studio.prj_prod.this_proj.this_prod.list_kebab_edit_page_name_btn',
    value: PRODUCT_PAGE_ACTION.EDIT
  },
  {
    label: 'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_btn',
    value: PRODUCT_PAGE_ACTION.DUPLICATE
  },
  {
    label: 'studio.prj_prod.this_proj.this_prod.list_kebab_display_y_btn',
    value: PRODUCT_PAGE_ACTION.RESUME
  },
  {
    label: 'studio.prj_prod.this_proj.this_prod.list_kebab_display_n_btn',
    value: PRODUCT_PAGE_ACTION.SUSPEND
  },
  {
    label: 'studio.prj_prod.this_proj.this_prod.list_kebab_delete_btn',
    value: PRODUCT_PAGE_ACTION.DELETE
  }
];

const total = ref<number>(0);
const currentPage = ref<number>(1);
const direction = ref<string>(SORT_DIRECTION.DESC);
const pageSize = 10;

// will optimize this when integrate real api
const getMoreOptions = (row: ProductPageResponse) => {
  switch (row.progressStatus) {
    case PROGRESS_STATUS.LIVE:
      if (row.pageDisplayYn === Confirmation.YES) {
        return moreOptions.filter(
          (option: FormOption<ProductPageActionType>) =>
            option.value === PRODUCT_PAGE_ACTION.SUSPEND ||
            option.value === PRODUCT_PAGE_ACTION.DUPLICATE
        );
      }
      if (row.pageDisplayYn === Confirmation.NO) {
        return moreOptions.filter(
          (option: FormOption<ProductPageActionType>) =>
            option.value === PRODUCT_PAGE_ACTION.RESUME ||
            option.value === PRODUCT_PAGE_ACTION.DUPLICATE
        );
      }
      return moreOptions.filter(
        (option: FormOption<ProductPageActionType>) =>
          option.value === PRODUCT_PAGE_ACTION.DUPLICATE
      );
    case PROGRESS_STATUS.REJECT:
    case PROGRESS_STATUS.SUSPEND:
    case PROGRESS_STATUS.NONE:
      return moreOptions.filter(
        (option: FormOption<ProductPageActionType>) =>
          option.value !== PRODUCT_PAGE_ACTION.RESUME &&
          option.value !== PRODUCT_PAGE_ACTION.SUSPEND
      );
    case PROGRESS_STATUS.READY:
      return moreOptions.filter(
        (option: FormOption<ProductPageActionType>) =>
          option.value === PRODUCT_PAGE_ACTION.EDIT ||
          option.value === PRODUCT_PAGE_ACTION.DUPLICATE ||
          option.value === PRODUCT_PAGE_ACTION.DELETE
      );

    // TEST PURPOSE
    // return moreOptions;
    case PROGRESS_STATUS.REVIEW:
    case PROGRESS_STATUS.END:
      return moreOptions.filter(
        (option: FormOption<ProductPageActionType>) =>
          option.value === PRODUCT_PAGE_ACTION.DUPLICATE
      );
  }
};

const path = computed(() => {
  const { projectId, productId } = route.params;
  return `/${selectedGroupId.value}/projects/${projectId}/products/${productId}/product-pages`;
});

const onInputProductName = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  const dialog = await showDialog<{
    success?: boolean;
    pageId?: string;
  }>({
    component: shallowRef(InputProductNameDialog),
    props: {},
    severity: 'info'
  });

  if (dialog.success && dialog.pageId) {
    await redirectTo(`${path.value}/${MODE.EDIT}`, { query: { pageId: dialog.pageId } });
  }
};

const getPeriodTime = (startAt?: number, endAt?: number) => {
  if (!startAt && !endAt) {
    return '-';
  }

  return `${startAt ? getDateTimeByLocale(startAt) : ''} ~ ${
    endAt ? getDateTimeByLocale(endAt) : ''
  }`;
};

const fetchProductPages = async (params: { sortBy?: string }) => {
  const { sortBy } = params;

  const result = await fetchProductPageInfoApi({
    productNo: Number(route.params.productId),
    page: currentPage.value,
    size: pageSize,
    direction: direction.value,
    sortBy
  });

  if (result) {
    productPageList.value = result.contents.map((page: ProductPageResponse) => ({
      ...page,
      period: getPeriodTime(page.scheduledStartedAt, page.scheduledEndedAt),
      options: getMoreOptions(page),
      statusLabel: getStatusLabel(page.progressStatus),
      id: page.pageId
    }));

    total.value = result.totalElements;
  }
};

const onDeleteProductPage = async (productPage: ProductPageModel) => {
  const dialog = await showConfirm({
    content:
      productPage.progressStatus === PROGRESS_STATUS.READY
        ? t('studio.prj_prod.this_proj.this_prod.list_kebab_delete_pop_msg')
        : t('studio.prj_prod.this_proj.this_prod.list_kebab_delete_pop_msg_case2'),
    confirmLabel: t('studio.prj_prod.this_proj.this_prod.list_kebab_delete_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    confirmClasses: '!w-full !max-w-full',
    confirmVariant: 'red',
    cancelVariant: 'outline'
  });

  if (dialog) {
    try {
      const result = await deleteProductPageApi(productPage.pageId);

      if (result === null) {
        await showAlert({
          content: t('studio.common.popup_case_f_complete_del'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });

        await init();
      }
    } catch (error) {
      const err = error as ErrorResponse;
      if (err?.statusCode === STATUS_CODE.PRODUCT_PAGE_PUBLISHED) {
        await showAlert({
          content: t('studio.prj_prod.this_proj.this_prod.list_kebab_delete_x_msg'),
          severity: 'info',
          confirmClasses: '!w-full !max-w-full'
        });
      }

      if (err?.statusCode === STATUS_CODE.PRODUCT_PAGE_MANDATORY_RELEASE) {
        await showAlert({
          content: t('studio.prod_page.one_page_only.cannot_del_msg'),
          severity: 'info',
          confirmClasses: '!w-full !max-w-full'
        });
      }
    }
  }
};

const onDuplicateProductPage = async (productPage: ProductPageModel) => {
  const duplicateResult = await showDialog({
    component: shallowRef(DuplicateProductDialog),
    props: {
      productPage
    },
    severity: 'info'
  });

  if (duplicateResult) {
    await fetchProductPages({});
  }
};

const onEditProductPageName = async (productPage: ProductPageModel) => {
  const result = await showDialog<{
    success?: boolean;
    pageId?: string;
  }>({
    component: shallowRef(InputProductNameDialog),
    props: {
      currentPageName: productPage.pageName,
      currentPageId: productPage.pageId
    },
    severity: 'info'
  });

  if (result.success) {
    await fetchProductPages({});
  }
};

const onModifyDisplayProductPage = async (productPageId: string, displayYn: Confirmation) => {
  const result = await showDialog<boolean>({
    component: shallowRef(ModifyDisplayDialog),
    props: {
      displayYn,
      productPageId
    },
    severity: 'info'
  });

  if (result) {
    await fetchProductPages({});
  }
};

const handleMoreOptionClick = async (
  productPage: ProductPageModel,
  menuOption: ProductPageActionType
) => {
  if (!(await checkProductPermission())) {
    return;
  }

  switch (menuOption) {
    case PRODUCT_PAGE_ACTION.EDIT:
      onEditProductPageName(productPage);
      break;
    case PRODUCT_PAGE_ACTION.DELETE:
      onDeleteProductPage(productPage);
      break;
    case PRODUCT_PAGE_ACTION.DUPLICATE:
      onDuplicateProductPage(productPage);
      break;
    case PRODUCT_PAGE_ACTION.RESUME:
      onModifyDisplayProductPage(productPage.pageId, Confirmation.YES);
      break;
    case PRODUCT_PAGE_ACTION.SUSPEND:
      onModifyDisplayProductPage(productPage.pageId, Confirmation.NO);
      break;
  }
};

const onPageChange = async (page: number) => {
  currentPage.value = page;
  await fetchProductPages({});
};

const handleSort = (sortValue: number, sortKey: string) => {
  const dir = sortValueToDirection(sortValue);

  if (!dir) {
    direction.value = SORT_DIRECTION.DESC;
    fetchProductPages({});
    return;
  }

  direction.value = dir;
  fetchProductPages({
    sortBy: sortKey
  });
};

const handleGoToStore = (page: ProductPageModel) => {
  if (page.progressStatus !== PROGRESS_STATUS.LIVE) {
    return;
  }
  const { STORE_URL } = getConfigs();
  const storeUrl = `${STORE_URL}/${locale.value || DEFAULT_LOCALE}/games/${productNo}`;
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: storeUrl
  }).click();
};

const init = async () => {
  await fetchProductPages({});
};

init();
</script>
