<template>
  <div>
    <s-dialog to="stds-dialog-duplicate-product-page" size="lg" :open="true">
      <s-dialog-overlay />
      <s-dialog-panel class="!h-[73.2rem]">
        <st-dialog-header @clickClose="closeDialog">
          <span class="font-bold">
            {{ $t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_title') }}
          </span>
        </st-dialog-header>
        <s-dialog-content class="flex-1 overflow-hidden">
          <s-dialog-content-body class="flex flex-col h-full">
            <p class="shrink-0 text-sm leading-md text-on-surface-elevation-3">
              {{ $t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_msg') }}
            </p>
            <div class="flex items-center gap-20 mt-20">
              <div class="flex-1 flex items-center gap-8">
                <strong class="text-md font-bold leading-sm">{{
                  $t(
                    'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_prod_page_count_title'
                  )
                }}</strong>
                <span class="flex items-center text-xl leading-xl text-placeholder">
                  <em class="text-brand-primary font-bold">{{ selectedProductsId.length }}</em>
                  /{{ totalElements }}
                </span>
              </div>

              <input-text
                v-model="searchInputOutline"
                :placeholder="
                  $t(
                    'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_search_place_holder'
                  )
                "
                searchable
                containerClass="w-[22.5rem]"
                @search="onSearch"
                @clear="onSearch"
              />
            </div>
            <div class="mt-16 relative flex-1 studio-scrollbar-4 max-h-[478rem]">
              <st-table
                :key="tableKey"
                v-model:checkBoxValue="selectedAllCurrentPage"
                v-model:currentPage="currentPage"
                tableClass="flex-1"
                :columns="columns"
                :data="data"
                headClass="sticky top-0 z-[601]"
                @onCheckboxUpdate="handleCheckAll"
              >
                <template #empty>
                  <st-td colspan="2" class="h-[50rem]">
                    <p
                      class="flex-1 flex items-center justify-center py-16 text-center text-md leading-lg text-on-surface-elevation-2"
                    >
                      {{
                        $t(
                          'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_search_nmrf'
                        )
                      }}
                    </p>
                  </st-td>
                </template>
                <template #col-0="{ row }">
                  <st-td aLeft>
                    <div class="h-16">
                      <checkbox
                        :modelValue="row.data"
                        :options="{
                          size: 'sm',
                          align: 'middle'
                        }"
                        @update:modelValue="onSelectPage($event, row.value)"
                      />
                    </div>
                  </st-td>
                </template>
                <template #col-1="{ row }">
                  <st-td aLeft>
                    <div class="h-16">
                      <s-tooltip
                        :content="`<p class='break-all'>${row.label}</p>`"
                        :duration="0"
                        placement="bottom-start"
                        trigger="mouseenter focus"
                        :theme="'studio-tooltip'"
                        class="truncate w-full block"
                        :allowHTML="true"
                        :zIndex="2501"
                      >
                        <template #target>
                          {{ row.label }}
                        </template>
                      </s-tooltip>
                    </div>
                  </st-td>
                </template>
              </st-table>
            </div>
            <s-pagination
              v-if="data.length > 0"
              v-model="currentPage"
              :pageCount="pageCount"
              displayFirstAndLastButton
              class="!py-32"
            />
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            class="sm:min-w-264"
            :isDisabled="selectedProductsId.length === 0"
            @click="onSubmit"
          >
            {{ $t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_duplicate_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-duplicate-product-page" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { duplicateProductPageApi } from '@/apis/product-page.api';
import { searchProductsApi } from '@/apis/products.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert } from '@/composables/useDialog';
import { SORT_VALUE } from '@/constants/common.const';
import { ROLE_ID } from '@/constants/permission.const';
import { DEFAULT_PAGE_SIZE } from '@/constants/table.const';
import { Permission } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type { ProductSearchItem } from '@/types/product/product-response.type';
import type { ProductPageModel } from '@/types/product-page/product-page-model.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { clientSideSort } from '@/utils/table.util';

const MAX_PAGES_SELECTED = 50;

interface FormOptionChecked<T = string> extends FormOption<T> {
  checked?: boolean;
}

const props = defineProps<{
  productPage: ProductPageModel;
}>();

const emit = defineEmits<{
  close: [v?: boolean];
}>();

const columns: Column[] = [
  {
    title: '',
    width: '6.4rem',
    align: 'left',
    headClass: '[&>div>.mr-4]:!h-16 [&>div>.mr-4]:!mr-0 [&>div>.stds-tooltip]:!hidden',
    checkable: true
  },
  {
    title: 'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_product_header2',
    align: 'left',
    dataIndex: 'label',
    sortKey: 'label',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortProducts(sortValue, sortKey),
    cellClass: 'whitespace-pre'
  }
];

const { t, locale } = useI18n();

const userStore = useUserStore();

const { selectedGroupId } = storeToRefs(userStore);

const searchInputOutline = ref('');
const totalElements = ref<number>(0);
const currentPage = ref<number>(1);
const isSorting = ref<boolean>(false);
const tableKey = ref<number>(0);

const searchResult = ref<FormOption<number, boolean>[]>([]);
const originalList = ref<FormOption<number, boolean>[]>([]);
const updatedSelectedProducts = ref<FormOption<number, boolean>[]>([]);

const selectedProductsId = computed(() =>
  originalList.value
    .filter((p: FormOption<number, boolean>) => p.data)
    .map((p: FormOption<number, boolean>) => p.value)
);

const pageCount = computed(() => {
  return Math.ceil(searchResult.value.length / DEFAULT_PAGE_SIZE);
});

const data = computed(() => {
  return searchResult.value.slice(
    (currentPage.value - 1) * DEFAULT_PAGE_SIZE,
    currentPage.value * DEFAULT_PAGE_SIZE
  );
});

// All row on the current page is selected
const selectedAllCurrentPage = computed(() =>
  data.value.every((p: FormOption<number, boolean>) => p.data)
);

const onSearch = () => {
  let temp = originalList.value;
  if (isSorting.value) {
    temp = updatedSelectedProducts.value;
  }

  searchResult.value = temp
    .filter((r: FormOption<number>) =>
      r.label.toLowerCase().includes(searchInputOutline.value.toLowerCase())
    )
    .map((r: FormOption<number>) => {
      if (selectedProductsId.value.find((c: number) => c === r.value)) {
        return {
          ...r,
          data: true
        };
      }
      return r;
    }) as FormOption<number, boolean>[];

  currentPage.value = 1;
};

const onSubmit = async () => {
  if (selectedProductsId.value.length > MAX_PAGES_SELECTED) {
    showAlert({
      content: t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_limit_exceed_msg'),
      severity: 'info',
      confirmClasses: '!w-full !max-w-full',
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });

    return;
  }

  try {
    const result = await duplicateProductPageApi({
      pageId: props.productPage.pageId,
      pageName: props.productPage.pageName,
      productNoList: selectedProductsId.value.map((p: number) => p)
    });

    if (result?.clones) {
      emit('close', true);
    }
  } catch (error) {}
};

const onSelectPage = ($event: boolean, id: number) => {
  const mapFunc = (r: FormOption<number, boolean>) => {
    if (r.value === id) {
      return {
        ...r,
        data: $event
      };
    }
    return r;
  };

  if ($event && selectedProductsId.value.length === MAX_PAGES_SELECTED) {
    showAlert({
      content: t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_limit_exceed_msg'),
      severity: 'info',
      confirmClasses: '!w-full !max-w-full',
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });

    tableKey.value = tableKey.value === 0 ? 1 : 0;

    return;
  }

  searchResult.value = searchResult.value.map(mapFunc) as FormOption<number, boolean>[];
  originalList.value = originalList.value.map(mapFunc) as FormOption<number, boolean>[];
};

const handleCheckAll = (event: boolean) => {
  const currentListNos = data.value.map((p: FormOptionChecked<number>) => p.value);
  const inCurrentNotSelected = currentListNos.filter(
    (c: number) => !selectedProductsId.value.includes(c)
  );

  if (selectedProductsId.value.length + inCurrentNotSelected.length > MAX_PAGES_SELECTED && event) {
    showAlert({
      content: t('studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_limit_exceed_msg'),
      severity: 'info',
      confirmClasses: '!w-full !max-w-full',
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });

    tableKey.value = tableKey.value === 0 ? 1 : 0;
    return;
  }

  // Update display data array
  searchResult.value = searchResult.value.map((r: FormOptionChecked<number>) => {
    if (currentListNos.includes(r.value)) {
      return {
        ...r,
        data: event
      };
    }
    return r;
  }) as FormOption<number, boolean>[];

  originalList.value = searchResult.value;
};

const closeDialog = () => {
  emit('close');
};

const onSortProducts = (sortValue: number, sortKey: string) => {
  isSorting.value = sortValue !== 0;

  if (sortValue === SORT_VALUE.NONE) {
    searchResult.value = originalList.value as FormOption<number, boolean>[];
  } else {
    const sortedArray = clientSideSort(
      originalList.value,
      sortKey,
      sortValue,
      locale.value
    ) as FormOption<number, boolean>[];

    updatedSelectedProducts.value = sortedArray;
    searchResult.value = sortedArray;
    currentPage.value = 1;
  }
};

const fetchProducts = async () => {
  const result = await searchProductsApi({
    groupId: selectedGroupId.value,
    page: currentPage.value,
    size: 9999,
    rolePermission: Permission.WRITE,
    roleAuthorityId: ROLE_ID.PRODUCT_STORE_PAGE
  });

  if (result?.contents) {
    const products = result.contents.map((r: ProductSearchItem) => ({
      value: r.productNo,
      label: `${r.projectName} - ${r.productName} (${r.productNo})`
    }));

    const dataWithChecked = products.map((p: FormOption<number>) => ({
      ...p,
      data: false
    }));

    searchResult.value = dataWithChecked;
    originalList.value = dataWithChecked;

    totalElements.value = result.totalElements;
  }
};

const init = async () => {
  fetchProducts();
};

init();
</script>
