<template>
  <div>
    <s-dialog :open="true" :to="uuid">
      <s-dialog-overlay />
      <s-dialog-panel class="w-[25em]">
        <s-dialog-content>
          <s-dialog-content-body>
            <div class="text py-16">
              <s-text as="h3" role="title7">
                {{
                  displayOn
                    ? $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop1_msg')
                    : $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop2_msg')
                }}
              </s-text>
              <s-text as="p" role="text3" class="mt-4 text-[#93969F]">
                {{
                  displayOn
                    ? $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop1_msg1')
                    : $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop2_msg1')
                }}
              </s-text>
            </div>
            <input-text
              name="displayInput"
              :placeholder="DISPLAY_TEXT"
              :clearable="false"
              inputClass="uppercase"
            />
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            :isDisabled="displayInput.toUpperCase() !== DISPLAY_TEXT"
            :variant="displayOn ? 'primary' : 'red'"
            @click="confirm"
          >
            {{
              displayOn
                ? $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop1_display_y_btn')
                : $t('studio.prj_prod.this_proj.this_prod.list_display_switch_pop2_msg')
            }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="uuid" />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useForm } from 'vee-validate';
import { computed, toRefs } from 'vue';

import { modifyProductPageDisplayApi } from '@/apis/product-page.api';
import InputText from '@/components/validation/input-text.vue';
import { Confirmation } from '@/enums/common.enum';
import type { ConfirmationType } from '@/types/common.type';
import { generateUUID } from '@/utils/uuid.util';

const props = defineProps<{
  displayYn: ConfirmationType;
  productPageId: string;
}>();

const DISPLAY_TEXT = props.displayYn === 'Y' ? 'ON' : 'OFF';

const { displayYn, productPageId } = toRefs(props);

const displayOn = computed(() => displayYn.value === Confirmation.YES);

const { handleSubmit } = useForm({
  initialValues: {
    displayInput: ''
  }
});

const displayInput = useFieldValue<string>('displayInput');

const uuid: string = `stds-dialog-${generateUUID()}`;

const emit = defineEmits<{ close: [v: boolean] }>();

const closeDialog = () => {
  emit('close', false);
};

const confirm = handleSubmit(async () => {
  if (displayInput.value.toUpperCase() === DISPLAY_TEXT) {
    try {
      const result = await modifyProductPageDisplayApi(
        productPageId.value,
        displayOn.value ? Confirmation.YES : Confirmation.NO
      );

      if (result === null) {
        emit('close', true);
      }
    } catch (error) {
      emit('close', false);
    }
  }
});
</script>
