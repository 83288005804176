<template>
  <div>
    <s-dialog :open="true" size="md" :to="`stds-dialog-input-product-name`">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body class="pt-28 !pb-56">
            <p class="text-center text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{ $t('studio.prj_prod.this_prod.landing_create_pop_msg1') }}
            </p>
            <p class="mt-4 text-center text-lg leading-lg text-on-surface-elevation-4">
              {{ $t('studio.prj_prod.this_prod.landing_create_pop_msg2') }}
            </p>
            <input-text
              name="productPageName"
              containerClass="mt-16"
              :placeholder="
                $t('studio.prj_prod.this_prod.landing_create_pop_page_name_place_holder')
              "
              :rules="{
                required: $t('studio.prj_prod.this_prod.landing_create_pop_input_msg1'),
                max_length: 60
              }"
              maxLength="60"
              :allowInputMaxLength="false"
            />
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" @click="closeDialog">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button @click="onSubmit">
            {{
              currentPageName
                ? $t('studio.common.popup_case_save_btn')
                : $t('studio.prj_prod.this_prod.landing_create_pop_create_btn')
            }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-input-product-name`" />
  </div>
</template>
<script setup lang="ts">
import { useFieldValue, useForm } from 'vee-validate';
import { useRoute } from 'vue-router';

import { createSimpleProductPageApi, modifyProductPageNameApi } from '@/apis/product-page.api';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { showCommonErrorDialog } from '@/utils/common.util';

const props = defineProps<{
  currentPageName?: string;
  currentPageId?: string;
}>();

const emit = defineEmits<{
  // string is for create, boolean is for edit
  close: [
    v: {
      success?: boolean;
      pageId?: string;
    }
  ];
}>();

const { checkProductPermission } = useApp();

const route = useRoute();
const { productId } = route.params;

const { handleSubmit } = useForm({
  initialValues: {
    productPageName: props.currentPageName || ''
  }
});

const productPageName = useFieldValue<string>('productPageName');

const closeDialog = () => {
  emit('close', {
    success: false
  });
};

const onSubmit = handleSubmit(async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  if (props.currentPageName) {
    if (productPageName.value.trim() === props.currentPageName) {
      closeDialog();
      return;
    }

    if (!props.currentPageId) {
      return;
    }

    try {
      const result = await modifyProductPageNameApi(productPageName.value, props.currentPageId);
      if (result === null) {
        emit('close', {
          success: true
        });
      }
    } catch (error) {
      await showCommonErrorDialog(COMMON_ERROR_MESSAGE_KEY);
    }

    return;
  }

  const res = await createSimpleProductPageApi({
    pageName: productPageName.value,
    productNo: Number(productId)
  });
  if (res) {
    emit('close', {
      success: true,
      pageId: res.pageId
    });
  }
});
</script>
